import {createApp} from 'vue'
import {createRouter, createWebHistory} from 'vue-router'

import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import App from './App.vue'


const navigations = [
    {
        path: '/',
        component: () => import('@/components/AppDashboard'),
        meta: {
            title: 'Dashboard',
            icon: 'b-icon-house',
        }
    },
    {
        path: '/advert',
        component: () => import('@/components/pages/adverts/list'),
        meta: {
            title: 'Adverts',
            icon: 'b-icon-columns-gap',
        }
    },
    {
        path: '/users',
        component: () => import('@/components/pages/users/list'),
        meta: {
            title: 'Users',
            icon: 'b-icon-people',
        }
    },
    {
        path: '/users/:id',
        component: () => import('@/components/pages/users/update'),
    },
    {
        path: '/telegram/messages',
        component: () => import('@/components/pages/telegram/messages/list'),
        meta: {
            title: 'Telegram Messages',
            icon: 'b-icon-envelope-open',
        }
    },
    {
        path: '/telegram/feedback',
        component: () => import('@/components/pages/telegram/feedback/list'),
        meta: {
            title: 'Telegram Feedback',
            icon: 'b-icon-chat-left-dots',
        }
    }
]

function generateRoutes(navigationItems) {
    const routes = [];

    navigationItems.forEach(nav => {
        if (nav.path) {
            routes.push({
                path: nav.path,
                component: nav.component,
                meta: nav.meta || {},
            });
        }

        if (nav.children) {
            nav.children.forEach(child => {
                routes.push({
                    path: child.path,
                    component: child.component,
                    meta: child.meta || {},
                });
            });
        }
    });

    return routes;
}

const routes = generateRoutes(navigations);

const router = createRouter({
    history: createWebHistory(),
    routes,
})

const app = createApp(App)
app.use(router)
app.provide('navigations', navigations);
app.use(BootstrapVue)
app.use(BootstrapVueIcons)
app.mount('#app')
