<template>
  <AppHeader/>
  <div class="main">
    <div class="sidebar bg-light">
      <AppNavigation/>
    </div>
    <div class="content">
      <router-view/>
    </div>
  </div>
</template>

<script>

import AppHeader from "@/components/AppHeader.vue";
import AppNavigation from "@/components/AppNavigation.vue";

export default {
  components: {
    AppHeader,
    AppNavigation
  },
};
</script>

<style>
.main {
  margin-top: 56px;
}

.sidebar {
  height: 100vh;
  width: 240px;
  position: fixed;
  border-right: 1px solid rgba(0, 0, 0, .1);
}

.content {
  padding: 20px;
  margin-left: 240px;
  overflow: auto;
}
</style>
