<script setup>
import {inject} from 'vue';

const links = inject('navigations').filter(link => link.meta);
</script>

<template>
  <ul class="navigation-list">
    <li class="navigation-item" v-for="link in links" :key="link.path">
      <RouterLink :to="link.path">
        <component :is="link.meta.icon" class="link-icon"></component>
        {{ link.meta.title }}
      </RouterLink>
    </li>
  </ul>
</template>

<style scoped>
.navigation-list {
  width: 100%;
  padding: 16px;
  flex-direction: column;
}

.navigation-item {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.navigation-item a {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 0 12px;
}

.navigation-item a:hover {
  background-color: rgb(235, 236, 240);
}

.link-icon {
  margin-right: 16px;
  font-size: 22px;
}

.router-link-active {
  background-color: rgb(235, 236, 240);
}
</style>
